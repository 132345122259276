import type { ComponentType } from "react"
import React, { useState, useEffect } from "react"
import bcrypt from "bcryptjs"

export function withPasswordProtection(
    Component: ComponentType
): ComponentType {
    const hashedCorrectPassword =
        "$2y$10$Q3Pz7yChAwixLPyzTGPkfe4N1ECyLRTbqa8DlYuU3c9QWVPHp1.6." // Encrypt your desired password using this link https://bcrypt.online than replace its value above, do not remove the double quotes

    return function WithPasswordProtection(props) {
        const [password, setPassword] = useState("")
        const [showContent, setShowContent] = useState(false)
        const [showForm, setShowForm] = useState(false)

        useEffect(() => {
            const userPassword = localStorage.getItem("userPassword")

            if (userPassword) {
                bcrypt.compare(
                    userPassword,
                    hashedCorrectPassword,
                    (err, match) => {
                        if (err) {
                            console.error("Error comparing passwords:", err)
                            return
                        }

                        if (match) {
                            setShowContent(true)
                            console.log("Password validation successful")
                        } else {
                            console.log("Incorrect password")
                        }
                    }
                )
            }

            setShowForm(true)
        }, [])

        const handlePasswordChange = (
            event: React.ChangeEvent<HTMLInputElement>
        ) => {
            setPassword(event.target.value)
        }

        const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()

            bcrypt.compare(password, hashedCorrectPassword, (err, match) => {
                if (err) {
                    console.error("Error comparing passwords:", err)
                    return
                }

                if (match) {
                    setShowContent(true)
                    console.log("Password validation successful")

                    localStorage.setItem("userPassword", password)
                } else {
                    console.log("Incorrect password")
                }
            })
        }

        const formStyle = {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#112233",
            color: "#DDEEFF",
            height: "65vh",
            opacity: showForm ? 1 : 0,
            transition: "opacity 1s ease",
            width: "100%",
        }

        const inputStyle = {
            padding: "12px 20px 10px 20px",
            borderRadius: "8px 0 0 8px",
            border: "none",
            borderRight: "none",
            fontSize: "16px",
            width: "240px",
            maxWidth: "100%",
            height: "45px",
            outline: "none",
        }

        const buttonStyle = {
            padding: "12px 20px 10px 20px",
            borderRadius: "0 8px 8px 0",
            border: "none",
            background: "#66FFCC",
            color: "#112233",
            fontSize: "16px",
            fontWeight: "bold",
            cursor: "pointer",
            boxShadow: "none",
            height: "45px",
        }

        const wrapperStyle = {
            boxShadow:
                "rgba(0, 0, 0, 0.26) 0px 0.637473px 1.147451px -1.125px, rgba(0, 0, 0, 0.24) 0px 1.930942px 3.475695px -2.25px, rgba(0, 0, 0, 0.192) 0px 5.104227px 9.187608px -3.375px, rgba(0, 0, 0, 0.03) 0px 16px 28.8px -4.5px",
            borderRadius: "10px",
        }

        return (
            <>
                {showContent ? (
                    <Component {...props} />
                ) : (
                    <form onSubmit={handleSubmit} style={formStyle}>
                        <div style={wrapperStyle}>
                            <input
                                type="password"
                                placeholder="Enter password"
                                style={inputStyle}
                                value={password}
                                onChange={handlePasswordChange}
                            />
                            <button type="submit" style={buttonStyle}>
                                Submit
                            </button>
                        </div>
                    </form>
                )}
            </>
        )
    }
}
